import React, { useEffect, useState } from 'react';
import { useStatsigClient } from '@statsig/react-bindings';
import SvgThumbsUp from 'src/assets/svg/SvgThumbsUp.svg';
import ModalMobile from '../../components/modal-mobile';
import SniffButton from '../../components/sniff-button';

import CookieService from '../../services/cookie.service';
import useMutateViewHappyDogTest from '../../services/hooks/useMutateViewHappyDogTest';
import { COOKIE_PARAM_NAME } from '../../consts/cookies.consts';
import { USER_TEST_GROUP } from '../../consts/profile.const';

import './render-happy-dog-guarantee.scss';

const HappyDogGuarantee = ({ happyDogModal, toggle }) => (
    <ModalMobile isOpen={happyDogModal} toggle={toggle} title="Happy Dog Guarantee">
        <p className="snif-p">
            We guarantee that you and your dog will be happy with your visit. If you are not happy with your visit, we will refund you.
            <br />
            <br />
            Read our policy{' '}
            <a
                target="_blank"
                rel="noreferrer"
                href="https://help.sniffspot.com/article/132-what-is-sniffspots-guest-refund-policy"
                className="snif-semibold cursor-pointer text-primary"
            >
                here
            </a>
            .
        </p>
        <SniffButton className="w-100 mt-2 mb-1" color="secondary" size="lg" onClick={toggle}>
            Ok, close
        </SniffButton>
    </ModalMobile>
);

const RenderHappyDogGuarantee = ({ isListing, isHappyDogTest, mutateViewHappyDogTest = () => {} }) => {
    const [happyDogModal, setHappyDogModal] = useState(false);
    const { checkGate } = useStatsigClient();
    const isGate = isListing ? checkGate(USER_TEST_GROUP.GUEST_HAPPY_DOG_TEST.toLowerCase()) : isHappyDogTest;
    const mutate = isListing ? useMutateViewHappyDogTest().mutateViewHappyDogTest : mutateViewHappyDogTest;

    useEffect(() => {
        if (isGate !== null) {
            CookieService.set(COOKIE_PARAM_NAME.GUEST_HAPPY_DOG_TEST, isGate ? 'NEW' : 'OLD', { expires: 90 });
            mutate({
                variables: { testGroup: isGate, sessionUuid: CookieService.get(COOKIE_PARAM_NAME.SESSION_UUID) },
            });
        }
    }, [isGate]);

    return (
        isGate && (
            <div
                className={isListing ? 'snif-regular listing-happy-dog-guarantee' : 'spot-detail-happy-dog-guarantee'}
                style={{ backgroundColor: '#EBF9FC' }}
            >
                <SvgThumbsUp style={{ color: '#4F96C4' }} />
                <div className="snif-p deep-gray">
                    <p>
                        We guarantee your dog will be happy with their visit - or we'll make it right.{' '}
                        <span className="snif-medium text-underline" onClick={() => setHappyDogModal(true)}>
                            Learn more.
                        </span>
                    </p>
                </div>
                <HappyDogGuarantee happyDogModal={happyDogModal} toggle={() => setHappyDogModal(false)} />
            </div>
        )
    );
};

export default RenderHappyDogGuarantee;
