import React from 'react';
import ReactSwipe from 'react-swipe';
import { PREVIEW_TYPES } from '../../consts/images.const';
import PaginationNext from '../../components/pagination/next';
import SkeletonSpotItem from '../skeleton/spot-item';
import EmptySpots from '../empty/empty-spots';
import Item from '../listing-item/item-ssr';
import RenderHappyDogGuarantee from '../../pages/spot-view-page/render-happy-dog-guarantee';
import './index.scss';

const ListingSSR = ({
    ListingItem = Item,
    items,
    inRow,
    loading,
    lastBtn,
    noEmpty = false,
    setPage,
    isMobile,
    homePage,
    showDesc,
    metadata,
    onScroll,
    useState,
    useEffect,
    className,
    firstEager,
    countSpots,
    usePrevious,
    disableSwipe,
    noAskForAlert,
    addNewSpotFilter,
    noRich,
    contentTest,
    listingPage,
    listingServer,
}) => {
    let swipeRef;
    const lastPage = metadata?.currentPage === metadata?.totalPages || metadata?.totalPages === 0;
    const currPagePrev = usePrevious(metadata?.currentPage);
    const step = 4;
    const array_items = Array(Math.ceil(items.length / step))
        .fill()
        .map((_, index) => index * step)
        .map((begin) => items.slice(begin, begin + step));

    useEffect(() => {
        if (currPagePrev > metadata?.currentPage) {
            swipeRef.prev();
        } else if (currPagePrev < metadata?.currentPage) {
            swipeRef.next();
        }
    }, [currPagePrev]);

    return (
        <div onScroll={onScroll} className={`${className ? className : ''} ${isMobile ? ' mobile' : ''}`}>
            <div
                className={`d-flex justify-content-right spots-container ${inRow ? 'in-row' : 'flex-wrap'} ${
                    countSpots > 1 ? 'indent-many' : 'indent-one'
                }`}
                ref={(el) => (swipeRef = el)}
            >
                {loading && (
                    <div className="spots-spinner">
                        <div className="spinner" />
                    </div>
                )}
                {!loading && (
                    <>
                        {!isMobile && inRow ? (
                            <>
                                <ReactSwipe className="carousel" ref={(el) => (swipeRef = el)}>
                                    {array_items.map((items, i) => (
                                        <div key={i} className={`d-flex justify-content-${items.length < 4 ? 'right' : 'between'} in-row`}>
                                            {items.map((item, k) => (
                                                <ListingItem
                                                    key={item.id}
                                                    type={PREVIEW_TYPES.PREVIEW}
                                                    target={isMobile ? '_self' : '_blank'}
                                                    firstEager={firstEager ? k === 0 : false}
                                                    {...{
                                                        item,
                                                        homePage,
                                                        disableSwipe,
                                                        showDesc,
                                                        useState,
                                                        noRich,
                                                        listingPage,
                                                        contentTest,
                                                    }}
                                                />
                                            ))}
                                        </div>
                                    ))}
                                </ReactSwipe>
                                {lastBtn && lastBtn()}
                                {!noAskForAlert && (items.length < 1 || lastPage) && (
                                    <EmptySpots noItems={items.length < 1} addNewSpotFilter={addNewSpotFilter} />
                                )}
                                {!homePage && <PaginationNext className="px-2 px-md-0 w-100" {...{ metadata, setPage, items }} />}
                            </>
                        ) : (
                            <>
                                {!items.length
                                    ? listingServer
                                        ? [...Array(12)].map((_, i) => <SkeletonSpotItem key={i} className="listing-preview mb-3" />)
                                        : ''
                                    : items.map((item, k) => (
                                          <React.Fragment key={item.id}>
                                              <ListingItem
                                                  key={item.id}
                                                  type={PREVIEW_TYPES.PREVIEW}
                                                  target={isMobile ? '_self' : '_blank'}
                                                  firstEager={firstEager ? k == 0 : false}
                                                  {...{
                                                      item,
                                                      homePage,
                                                      disableSwipe,
                                                      showDesc,
                                                      isMobile,
                                                      useState,
                                                      useEffect,
                                                      noRich,
                                                      listingPage,
                                                      contentTest,
                                                  }}
                                              />
                                              {items.length > 1 && k === 2 && metadata?.currentPage === 1 && <RenderHappyDogGuarantee isListing />}
                                          </React.Fragment>
                                      ))}
                                {lastBtn && lastBtn()}
                                {!noEmpty && !noAskForAlert && lastPage && <EmptySpots noItems={lastPage} addNewSpotFilter={addNewSpotFilter} />}
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default ListingSSR;
