export function limitText(text = '', limit) {
    return text.length > limit ? `${text.slice(0, limit).trim()}...` : text.trim();
}

export function capitalizeText(str = '') {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
}

export function convertPaymentText(str) {
    return str
        .split('_') // Split the string by underscores
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
        .join(' '); // Join the words with a space
}
