import React from 'react';
import Carousel from '../../components/carousel';

const SingleViewPhoto = ({ spot, handleSwipeMobile, mobilePhotoCount, singleViewPhoto }) => {
    const caption = spot.spotPhotos[mobilePhotoCount]?.caption;

    return (
        <>
            <div className="single-view-photo">
                <Carousel
                    {...{
                        images: spot.allPhotos,
                        showArrow: false,
                        handleSwipeMobile,
                        singleViewPhoto: singleViewPhoto + 1,
                    }}
                    spotId={spot.id}
                    title={spot.title}
                    state={spot.state}
                    city={spot.city}
                />
            </div>
            {caption && <p className="snif-s1 mt-1 mx-2 mb-3">{caption}</p>}
            <div style={{ height: '82px' }} />
        </>
    );
};

export default SingleViewPhoto;
